.echo-contact-area{
    padding-top: 100px;
    padding-bottom: 110px;
    .echo-contact-content{
        .echo-contact-full-content{
            .echo-contact-input-field{
                .echo-contact-title{
                    margin-bottom: 28px;
                    h2{
                        margin: 0;
                        font-size: 50px;
                        line-height: 60px;
                    }
                }
                .echo-contact-sub-title{
                    margin-bottom: 20px;
                    p{
                        font-size: 16px;
                        line-height: 26px;
                        margin: 0;
                    }
                }
                .echo-main-contact-form{
                    form{
                        input{
                            border: 1px solid #5E5E5E45;
                            padding:0 40px;
                            height: 60px;
                        }
                        .echo-contact-input-flexing{
                            display: flex;
                            gap: 20px;
                            .echo-df-input{
                                position: relative;
                                margin-top: 20px;
                                &::before{
                                    position: absolute;
                                    top: 50%;
                                    left: 15px;
                                    transform: translateY(-50%);
                                    font-family: "Font Awesome 6 Pro" !important;

                                }
                               &.echo-contact-name{
                                &::before{
                                    content: '\f007';
                                }
                               }
                               &.echo-contact-email {
                                &::before{
                                    content: '\f0e0';
                                }
                               } 
                               &.echo-contact-number{
                                &::before{
                                    content: '\f095';
                                }
                               }
                               &.echo-contact-url{
                                &::before{
                                    content: '\f0ac';
                                }
                               }
                            }
                        }
                        .echo-contact-texting-fild{
                            margin-top: 20px;
                            position: relative;
                            textarea{
                                border: 1px solid #5E5E5E45;
                                height: 140px;
                                padding: 10px 0px 10px 40px;
                            }
                            &::before{
                                content: '\e212';
                                position: absolute;
                                font-family: "Font Awesome 6 Pro" !important;
                                left: 12px;
                                top: 8%;
                            }
                        }
                        .echo-contact-btn{
                            margin-top: 30px;
                            background: var(--color-primary);
                            width: 180px;
                            padding: 10px 20px;
                            color: #fff;
                            position: relative;
                            font-size: 16px;
                            line-height: 30px;
                            border: 1px solid transparent;
                            font-family: "Bona Nova", serif;
                            display: flex;
                            align-items: center;
                            transition:all 0.3s;
                            i{
                                margin-left: 10px;
                            }
                            &:hover{
                                border: 1px solid #45454530 ;
                                background: transparent;
                                color: #5E5E5E;
                            }
                        }
                    }
                }
            }
            .echo-info{
                background-color: #f9f9f9;
                padding: 35px;
                margin-left: 20px;
                .echo-address{
                    position: relative;
                    margin-bottom: 25px;
                    margin-left: 60px;
                    h6{
                        margin: 0;
                        font-size: 20px;
                        line-height: 30px;
                        margin-bottom: 5px;
                    }
                    span{
                        font-size: 16px;
                        line-height: 26px;
                    }
                    &:nth-child(1){
                        &::before{
                            content: '\f3c5';
                        }
                    }
                    &:nth-child(2){
                        &::before{
                            content: '\e170';
                        }
                    }
                    &:nth-child(3){
                        margin-bottom: 0;
                        &::before{
                            content: '\f095';
                        }
                    }
                    &::before{
                        font-family: "Font Awesome 6 Pro" !important;
                        position: absolute;
                        left: -65px;
                        top: 50%;
                        transform: translateY(-50%);
                        color: var(--color-primary);
                        width: 50px;
                        height: 50px;
                        background: #fff;
                        border: 1px solid transparent;
                        line-height: 50px;
                        border-radius: 50%;
                        text-align: center;
                    }
                }
               .echo-map{
                margin-top: 35px;
                .contact-map{
                    min-height: 295px;
                }
               } 
            }
        }
    }
    &.home-nine{
        background: url(../images/home-1/hero/contact-bg.png) !important;
        background-color: #003811;
        background-position: center;
        background-size: cover !important;
        background-repeat: no-repeat;
        height: 900px;
        position: relative;
        @media(max-width:576px){
            height: 600px;
        }
        .contact-inner{
            .inner-wrapper{
                background: #FFF;
                position: absolute;
                width: max-content;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                padding: 100px;
                @media(max-width:991px){
                    padding: 80px;
                }
                @media(max-width:768px){
                    padding: 50px;
                }
                .content-title{
                    font-size: 60px;
                    font-weight: 700;
                    line-height: 70px;
                    color: var(--color-heading-1);
                    @media(max-width:768px){
                        font-size: 42px;
                        line-height: 52px;
                    }
                    @media(max-width:576px){
                        font-size: 28px;
                        line-height: 40px;
                    }
                }
                .desc{
                    font-size: 20px;
                    line-height: 30px;
                    font-weight: 700;
                    color: #555;
                    padding-bottom: 40px;
                    border-bottom: 1px solid #2B583982;
                    @media(max-width:576px){
                        font-size: 18px;
                        line-height: 30px;
                    }
                }
                .social-area{
                    padding: 0;
                    margin: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 45px;
                    @media(max-width:768px){
                        gap: 20px;
                    }
                    li{
                        list-style: none;
                        transition: all .3s;
                        a{
                            font-size: 30px;
                            color: #999;
                            transition: all .3s;
                            @media(max-width:576px){
                                font-size: 20px;
                                line-height: 30px;
                            }
                            &:hover{
                                color: #FF9A82;
                            }
                        }
                    }
                }
            }
        }
    }
}