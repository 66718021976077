// **************************************** =========== Start Responsive  ===========  ****************************************//
/* Normal desktop :1200px. */
@media (min-width: 1200px) and (max-width: 1500px) {

	
	// Start Home-2
	.echo-hm2-video-area .echo-hm2-video-area-content .echo-hm2-video-area-full-content .echo-row .echo-hm2-video-img .echo-hm2-video-text-box {
		width: 100%;
		position: unset;
		transform: none;
		height: auto;
	}

	.echo-hm2-video-area .echo-hm2-video-area-content .echo-hm2-video-area-full-content .echo-row .echo-hm2-video-img {
		height: auto;
	}

	.echo-hm2-video-area .echo-hm2-video-area-content .echo-hm2-video-area-full-content .echo-row .echo-banner-slider-button {
		top: 22%;
	}

	// End Home-2

	// Start About Area
	.echo-about-area {
		padding: 50px 10px;
	}

	.echo-about-area .container .echo-about-area-inner .hero-image .image-2 {
		position: unset;
	}

	.echo-about-area .container .echo-about-area-inner .hero-image {
		gap: 20px;
	}

	// End About Area

}


/* Normal desktop :992px. */
@media (min-width: 992px) and (max-width: 1200px) {

	// Start Post Style 3
	.echo-post-style-3-hero-banner {
		margin-bottom: 25px;
	}

	// End Post Style 3

	// Start Post Style 2
	.echo-hero-section.inner-post-3 .echo-hero-discription {
		padding-top: 15px;
	}

	.post-style-3-lad-img {
		width: 100%;
	}

	// End Post Style 2

	// Start Post Style 1
	.echo-hero-section.inner-post .echo-financial-area .content .content-wrapper .wrapper-item {
		font-size: 16px;
		line-height: 26px;
	}

	.echo-hero-section.inner .echo-right-ct-1 .echo-feature-area-right-site-follower ul li:nth-child(6) a {
		gap: 7px;
	}

	.echo-de-category-show-more-btn {
		margin-top: 40px;
	}

	.echo-hero-section.inner-post .echo-financial-area .content .details-tag h6 {
		margin-right: 2px;
	}

	.echo-hero-section.inner-post .echo-author-area {
		padding: 20px 20px 20px 0;
		gap: 25px;
	}

	// End Post Style 1

	// Start About Area
	.echo-about-area {
		padding: 50px 10px;
	}

	.echo-about-area .container .echo-about-area-inner .hero-image .image-2 {
		position: unset;
	}

	.echo-about-area .container .echo-about-area-inner .hero-image {
		gap: 20px;
	}

	.echo-about-area .container .echo-about-area-inner .content-area .desc {
		margin-bottom: 15px;
	}

	.echo-about-area .container .echo-about-area-inner .content-area .bottom-author-area {
		margin-bottom: 15px;
	}

	// End About Area
	// Start Ct-3
	.echo-hero-section.inner-3 .echo-ct-style-bg-color .echo-griding-ct-style-3 .echo-hero-baner .echo-inner-img-ct-1 a>img {
		width: 100%;
	}

	.echo-hero-section.inner-3 .echo-hero-baner .echo-inner-img-ct-1 a>img {
		width: 100%;
	}

	.echo-hero-section.inner .echo-hero-baner {
		margin-bottom: 15px;
	}

	.echo-hero-section.inner-3 .echo-ct-style-bg-color .echo-popular-item-category {
		padding-top: 15px;
	}

	// End Ct-3

	// Ct Style 2
	.echo-hero-section.inner-2 .echo-hero-baner .echo-banner-texting h3 {
		font-size: 30px;
		line-height: 40px;
		padding-top: 0px;
		margin-bottom: 10px;
	}

	.echo-hero-section.inner-2 .echo-hero-baner .echo-inner-img-ct-1 {
		flex-basis: 41%;
	}

	.echo-hero-section.inner-2 .echo-hero-baner .echo-banner-texting {
		flex-basis: 55%;
	}

	.echo-hero-section.inner .echo-hero-baner .echo-hero-area-titlepost-post-like-comment-share {
		gap: 10px;
		flex-wrap: wrap;
	}

	.echo-hero-section.inner .echo-hero-baner p {
		margin-top: -15px;
	}

	.echo-hero-section.inner .echo-hero-baner .echo-hero-area-titlepost-post-like-comment-share {
		margin-bottom: 10px;
	}

	// Ct Style 2

	// Start Home-2
	.echo-hm2-video-area .echo-hm2-video-area-content .echo-hm2-video-area-full-content .echo-row .echo-hm2-video-img .echo-hm2-video-text-box {
		width: 100%;
		position: unset;
		transform: none;
		height: auto;
	}

	.echo-hm2-video-area .echo-hm2-video-area-content .echo-hm2-video-area-full-content .echo-row .echo-hm2-video-img {
		height: auto;
	}

	.echo-hm2-video-area .echo-hm2-video-area-content .echo-hm2-video-area-full-content .echo-row .echo-banner-slider-button {
		top: 22%;
	}

	// End Home-2

	// Start Author Area
	.echo-hero-section.inner .echo-right-ct-1 .echo-home-1-hero-area-top-story .echo-top-story .echo-story-text h6 {
		font-size: 16px;
		line-height: 26px;
	}

	.echo-hero-section.inner .echo-right-ct-1 .echo-feature-area-right-site-follower ul li:nth-child(6) {
		padding-left: 7px;
	}

	.echo-hero-section.inner .echo-right-ct-1 .echo-feature-area-right-site-follower ul li {
		font-size: 14px;
	}

	.echo-hero-section.inner .echo-right-ct-1 .echo-feature-area-right-site-newsletter .echo-feature-area-right-site-news-subscribe form .echo-feature-area-right-site-input-box button {
		margin-left: -30px;
	}

	// End Author Area

	// Start Category Style 1
	.echo-hero-section.inner .echo-hero-baner h2 {
		margin-top: 20px;
		line-height: 50px;
		font-size: 40px;
		margin-bottom: 10px;
	}

	// End Category Style 1

	// Start Social Media Home-2
	.echo-social-media-area-hm2 {
		padding: 30px 0;
	}

	// End Social Media Home-2
	// Start Archive Area Home-2
	.echo-archive-area {
		padding: 30px 0;
	}

	.echo-archive-area .echo-archive-area-content .echo-archive-area-full-content .echo-archive-area-right-site .echo-archive-right-content .echo-archive-right-text .echo-3-align-icons {
		margin: 10px 0;
	}

	.echo-archive-area .echo-archive-area-content .echo-archive-area-full-content .echo-archive-area-right-site .echo-archive-right-content .echo-archive-right-text .echo-archive-right-heading h5 {
		font-size: 20px;
		line-height: 30px;
	}

	.echo-archive-area .echo-archive-area-content .echo-archive-area-full-content .echo-archive-area-right-site .echo-archive-right-content {
		display: flex;
		gap: 10px;
	}

	.echo-archive-area .echo-archive-area-content .echo-archive-area-full-content .echo-archive-area-right-site .echo-archive-right-content .echo-archive-right-text,
	.echo-archive-area .echo-archive-area-content .echo-archive-area-full-content .echo-archive-area-right-site .echo-archive-right-content .echo-archive-right-img {
		flex-basis: auto;
	}

	// End Archive Area Home-2

	// Start Fd Post Area Home-2
	.echo-fd-post-area {
		padding: 30px 0;
	}

	.echo-fd-post-area .echo-fd-post-content .echo-fd-post-full-content .echo-fd-post-main-content .echo-fd-post-right-area .echo-fd-post-right-text .echo-fd-post-right-heading h4 {
		font-size: 20px;
		line-height: 30px;
	}

	.echo-fd-post-area .echo-fd-post-content .echo-fd-post-full-content .echo-fd-post-main-content .echo-3-align-icons {
		flex-wrap: wrap;
		gap: 10px;
	}

	.echo-fd-post-area .echo-fd-post-content .echo-fd-post-full-content .echo-fd-post-main-content .echo-fd-post-left-title h3 {
		font-size: 30px;
		line-height: 40px;
	}

	// End Fd Post Area Home-2

	// Start Cl Blocks Area Home-2
	.echo-cl-blocks-area {
		padding: 30px 0;
	}

	.echo-cl-blocks-area .echo-cl-blocks-content .echo-cl-blocks-full-content .echo-cl-blocks-text .echo-cl-blocks-area-shep {
		margin-top: 10px;
	}

	// End Cl Blocks Area Home-2

	// Start Popular Area Home-2
	.echo-popular-area {
		padding: 30px 0;
	}

	.echo-popular-area .echo-popular-content .echo-popular-full-content .echo-popular-tabing .echo-popular-tabing-btn .echo-popular-tab-button li {
		margin: 0 10px;
	}

	.echo-popular-area .echo-popular-content .echo-popular-full-content .echo-popular-tabing .echo-popular-tabing-btn .echo-popular-tab-button li::after {
		right: -10px;
	}

	.echo-popular-area .echo-popular-content .echo-popular-full-content .echo-popular-tabing .echo-popular-tabing-item-frist-row .echo-popular-left-jt-heading h3 {
		font-size: 25px;
		line-height: 35px;
	}

	.echo-popular-area .echo-popular-content .echo-popular-full-content .echo-popular-tabing .echo-popular-item-category .echo-popular-cat-content .echo-popular-cat-title h6 {
		font-size: 18px;
		line-height: 28px;
	}

	.echo-popular-area .echo-popular-content .echo-popular-full-content .echo-popular-tabing .echo-feature-area-right-site-follower ul li a {
		gap: 5px;
	}

	.echo-popular-area .echo-popular-content .echo-popular-full-content .echo-popular-tabing .echo-feature-area-right-site-follower ul li:nth-child(2),
	.echo-popular-area .echo-popular-content .echo-popular-full-content .echo-popular-tabing .echo-feature-area-right-site-follower ul li:nth-child(4) {
		padding-left: 10px;
	}

	.echo-popular-area .echo-popular-content .echo-popular-full-content .echo-popular-tabing .echo-feature-area-right-site-follower ul li {
		padding-top: 10px;
		font-size: 13px;
	}

	.echo-popular-area .echo-popular-content .echo-popular-full-content .echo-home-2-title h4 {
		font-size: 25px;
		line-height: 35px;
	}

	// End Popular Area Home-2

	// Start Feature Area Home-2
	.echo-home-2-feature-area {
		padding: 30px 0;
	}

	.echo-home-2-feature-area .echo-home-2-feature-area-content {
		padding: 30px 0;
	}

	.echo-home-2-feature-area .echo-feature-content .echo-feature-main-content .echo-feature-area-post-title h4 {
		font-size: 28px;
		line-height: 38px;
	}

	.echo-home-2-featurea-area-last-child-responsive // End Feature Area Home-2

	// Start Banner Area Home-2
	.echo-banner {
		padding: 30px 0;
	}

	.echo-banner .echo-banner-mani-content .echo-banner-text .echo-banner-heading h1 {
		font-size: 32px;
		line-height: 42px;
	}

	.echo-banner .echo-banner-mani-content .echo-banner-text {
		background-color: rgba(249, 249, 249, 0.7);
		padding: 20px;
		width: 641px;
	}

	.echo-banner .echo-banner-mani-content .echo-banner-text .echo-3-align-icons a {
		font-size: 14px;
		color: #5E5E5E;
	}

	.echo-banner .echo-banner-mani-content .echo-banner-text .echo-banner-heading {
		margin-top: 0;
	}

	.echo-banner .echo-banner-right-itme .echo-banner-right-heading h5 {
		font-size: 20px;
		line-height: 30px;
		margin-bottom: 15px;
	}

	// End Banner Area Home-2

	// Start Popular News Area Home-1
	.echo-popular-news-area {
		padding: 30px 0;
	}

	.echo-popular-news-area .echo-popular-area-item-title h5 {
		font-size: 20px;
		line-height: 30px;
	}

	.echo-popular-news-area .echo-popular-area-item-title {
		margin: 10px 0;
	}

	// End Popular News Area Home-1

	// Start Feature Area Home-1
	.echo-feature-area {
		padding: 30px 0;
	}

	.echo-feature-area .echo-feature-area-site-title h4::after {
		width: 50%;
		left: 40%;
	}

	.echo-feature-area .echo-feature-area-site-title .echo-feature-area-post-hins h5 {
		font-size: 19px;
	}

	.echo-feature-area .echo-feature-area-option .echo-feature-area-option-content .echo-feature-area-option-number h3 {
		font-size: 52px;
		line-height: 62px;
	}

	.echo-feature-area .echo-feature-area-option .echo-feature-area-option-content {
		padding: 13px;
		gap: 10px;
	}

	.echo-feature-area .echo-feature-area-option .echo-feature-area-option-content .echo-feature-area-option-content-text h5 {
		margin-bottom: 0px;
		font-size: 15px;
		line-height: 25px;
	}

	.echo-feature-area .echo-feature-area-last-news .echo-feature-area-last-content .echo-feature-area-last-content-text h3 {
		font-size: 30px;
		line-height: 40px;
	}

	// End Feature Area Home-1

	// Start Trending Area Home-1
	.echo-trending-area {
		padding: 30px 0;
	}

	// End Trending Area Home-1

	// Start Video Area Home-1
	.echo-video-area {
		padding: 30px 0;
	}

	.echo-video-area .echo-full-video-content .echo-video-right-site-content .echo-video-right-site-content-text {
		flex-basis: 81%;
	}

	.echo-video-area .echo-full-video-content .echo-video-right-site-content .echo-video-right-site-content-text h5 {
		font-size: 16px;
		line-height: 26px;
		margin-bottom: 8px;
	}

	.echo-video-area .echo-full-video-content .echo-video-right-site-content .echo-video-right-site-content-text>hr {
		margin-bottom: 5px !important;
	}

	.echo-video-area .echo-full-video-content .echo-video-right-site-content .echo-video-right-site-content-text>a {
		font-size: 15px !important;
	}

	.echo-video-area .echo-full-video-content .echo-video-right-site-content .echo-video-right-site-content-video .video-play-button {
		transform: translate(-50%, -50%) scale(0.5);
	}

	// End Video Area Home-1

	// Start De-Category Area Home-1
	.echo-de-category-area {
		padding: 30px 0;
	}

	.echo-de-category-area .echo-de-category-title-btn h4::after {
		width: 160%;
	}

	.echo-de-category-area .echo-de-category-content .echo-de-category-content-img-title .echo-de-category-content-title h6 {
		font-size: 16px;
		line-height: 29px;
	}

	.echo-feature-area .echo-feature-area-last-news .echo-feature-area-last-content .echo-feature-area-last-content-img>a>img {
		width: 100%;
	}

	.echo-feature-area .echo-feature-area-last-news .echo-feature-area-last-content {
		display: block;
	}

	// End De-Category Area Home-1
	.echo-footer-area4 {
		padding: 0 10px;
	}

}


/* Tablet desktop :768px. */
@media (min-width: 768px) and (max-width: 991px) {

	//Start Inner Section
	.echo-hero-section.inner {
		padding: 65px 20px;
	}

	.echo-hero-section.inner .echo-right-ct-1 .echo-home-1-hero-area-top-story .echo-top-story .echo-story-picture a img {
		width: unset;
		height: unset;
	}

	.echo-hero-section.inner .echo-right-ct-1 .echo-home-1-hero-area-top-story .echo-top-story .echo-story-picture {
		color: var(--colorPrimary);
	}

	.echo-hero-section.inner .echo-right-ct-1 .echo-home-1-hero-area-top-story .echo-top-story .echo-story-picture {
		flex-basis: auto;
	}

	// End Inner Section

	// Start Post Style 3
	.echo-post-style-3-hero-banner {
		width: 100%;
		margin-bottom: 25px;
	}

	.echo-hero-section.inner-post-2 .echo-financial-area .content .image.bottom>img {
		width: 100%;
	}

	.echo-hero-section.inner-post .echo-author-area .image-area {
		flex-basis: 23%;
	}

	.echo-post-style-3-2end {
		width: 100%;
	}

	// End Post Style 3
	// Start Post Style 1
	.post-style-1-frist-hero-img {
		width: 100%;
	}

	.echo-hero-section.inner-post-3 .echo-ps-2-subscribe-box .echo-ps-2-subscribe-all-content .echo-subscribe-input-fill form input {
		width: 100%;
	}

	.echo-hero-section.inner-post-3 .echo-ps-2-subscribe-box .echo-ps-2-subscribe-all-content .echo-subscribe-input-fill form .echo-ps-2-subscribe-btn {
		right: 0;
	}

	// End Post Style 1

	// Start About Area
	.echo-about-area .container .echo-about-area-inner .hero-image .image-2 {
		position: unset;
	}

	.echo-about-area .container .echo-about-area-inner .hero-image {
		gap: 30px;
		margin-top: 15px;
	}

	.echo-about-area .container .echo-about-area-inner .content-area .title {
		margin-bottom: 15px;
	}

	.echo-about-area .container .echo-about-area-inner .content-area .sub-title {
		font-size: 18px;
		line-height: 28px;
	}

	.echo-about-area .container .echo-about-area-inner .content-area .desc {
		margin-bottom: 15px;
	}

	.echo-about-area {
		padding: 50px 10px;
	}

	.echo-about-area .container .echo-about-area-inner .hero-image .image {
		width: 50%;
	}

	// End About Area

	//Start Ct-3
	.echo-ct-style-3-shep {
		bottom: -40px;
	}

	.echo-hm2-video-area .echo-hm2-video-area-content .echo-hm2-video-area-full-content .echo-row .echo-hm2-video-img .echo-hm2-video-text-box {
		width: 100%;
		position: unset;
		transform: none;
		height: auto;
	}

	// End Ct-3

	// Start Contact
	.echo-contact-area .echo-contact-content .echo-contact-full-content .echo-contact-input-field .echo-main-contact-form form .echo-contact-input-flexing .echo-df-input {
		flex-basis: 50%;
	}

	// End Contact

	// Start Home-2
	.echo-hm2-video-area .echo-hm2-video-area-content .echo-hm2-video-area-full-content .echo-row .echo-hm2-video-img .echo-hm2-video-text-box {
		width: 100%;
		position: unset;
		transform: none;
	}

	.echo-hm2-video-area .echo-hm2-video-area-content .echo-hm2-video-area-full-content .echo-row .echo-hm2-video-img {
		height: auto;
	}

	.echo-hm2-video-area .echo-hm2-video-area-content .echo-hm2-video-area-full-content .echo-row .echo-banner-slider-button {
		top: 22%;
	}

	// End Home-2

	// Start Author Area
	.echo-breadcrumb-area-2 .echo-author-content {
		display: block;
	}

	.echo-author-picture {
		text-align: center;
	}

	.echo-author-info {
		text-align: center;
		margin-top: 20px;
	}

	// End Author Area

	// Start Category Style 1
	.echo-right-ct-1 {
		margin-top: 40px;
	}

	// Start Category Style 1
	.echo-hero-section.inner .echo-hero-baner h2 {
		margin-top: 20px;
		line-height: 50px;
		font-size: 40px;
		margin-bottom: 10px;
	}

	.echo-hero-section.inner .echo-hero-baner .echo-ct-style-3-shep>p {
		padding: 0 40px;
	}

	.echo-ct-style-1-banner-images {
		width: 100%;
	}

	// End Category Style 1


	// Start Social Media Home-2
	.echo-social-media-area-hm2 {
		padding: 30px 0;
	}

	.echo-social-media-area-hm2 .echo-home-2-title .echo-home-2-main-title h4::before {
		display: none;
	}

	.echo-social-media-area-hm2 .echo-home-2-title .echo-home-2-main-title h4::after {
		display: none;
	}

	// End Social Media Home-2

	// Start Archive Area Home-2
	.echo-archive-area {
		padding: 30px 0;
	}

	.echo-archive-area .echo-archive-area-content .echo-archive-area-full-content .echo-archive-area-slider-content .echo-banner-mani-content .echo-banner-img>img {
		width: 100%;
	}

	.echo-archive-main-content.area-2 {
		margin-bottom: 30px;
	}

	.echo-archive-area .echo-archive-area-content .echo-archive-area-full-content .echo-archive-area-right-site .echo-archive-right-content {
		flex-direction: row-reverse;
		justify-content: left;
	}

	.echo-archive-area .echo-archive-area-content .echo-archive-area-full-content .echo-archive-area-right-site {
		max-width: max-content;
	}

	.echo-archive-area .echo-archive-area-content .echo-archive-area-full-content .echo-archive-area-right-site .echo-archive-right-content .echo-archive-right-text {
		flex-basis: auto;
	}

	.echo-archive-area .echo-archive-area-content .echo-archive-area-full-content .echo-archive-area-right-site .echo-archive-right-content .echo-archive-right-img {
		flex-basis: auto;
	}
    .echo-archive-area .echo-archive-area-content .echo-archive-area-full-content .echo-home-2-title .echo-home-2-main-title h4::after{
        width: 75%;
    }
	// End Archive Area Home-2

	// Start Fd Post Area Home-2
	.echo-fd-post-area {
		padding: 30px 0;
	}

	.echo-fd-post-area .echo-fd-post-content {
		padding: 40px 0;
	}

	.echo-fd-post-area .echo-fd-post-content .echo-fd-post-full-content .echo-fd-post-left .echo-fd-post-left-img>a>img {
		width: 100%;
	}

	.echo-fd-post-area .echo-fd-post-content .echo-fd-post-full-content .echo-fd-post-main-content .echo-fd-post-right-area .echo-fd-post-right-text .echo-fd-post-right-heading h4 {
		font-size: 20px;
		line-height: 30px;
	}

	.echo-fd-post-area .echo-fd-post-content .echo-fd-post-full-content .echo-fd-post-main-content .echo-fd-post-left-title h3 {
		font-size: 28px;
		line-height: 38px;
	}

	.echo-fd-post-area .echo-fd-post-content .echo-fd-post-full-content .echo-fd-post-main-content .echo-fd-post-left-title {
		margin-top: 10px;
	}

	.echo-fd-post-area .echo-fd-post-content .echo-fd-post-full-content .echo-fd-post-main-content .echo-3-align-icons {
		margin-top: 25px;
	}

	.echo-fd-post-area .echo-fd-post-content .echo-fd-post-full-content .echo-fd-post-main-content .echo-fd-post-left-title p {
		margin-top: 10px;
		font-size: 14px;
		line-height: 24px;
		margin-bottom: 10px;
	}

	.echo-fd-post-area .echo-fd-post-content .echo-fd-post-full-content .echo-fd-post-main-content .echo-fd-post-right-area .echo-fd-post-right-content img {
		width: auto;
	}

	.echo-fd-post-area .echo-fd-post-content .echo-fd-post-full-content .echo-fd-post-main-content .echo-fd-post-right-area .echo-fd-post-right-content {
		flex-basis: auto;
	}

	.echo-fd-post-area .echo-fd-post-content .echo-fd-post-full-content .echo-home-2-title .echo-home-2-main-title h4::before,
	.echo-fd-post-area .echo-fd-post-content .echo-fd-post-full-content .echo-home-2-title .echo-home-2-main-title h4::after {
		display: none;
	}

	.echo-fd-post-left {
		margin-bottom: 30px;
	}

	// End Fd Post Area Home-2

	// Start Cl Blocks Area Home-2
	.echo-cl-blocks-area {
		padding: 30px 0;
	}

	.echo-cl-blocks-area .echo-cl-blocks-content .echo-cl-blocks-full-content .echo-cl-blocks-img>a>img {
		width: 100%;
	}

	// End Cl Blocks Area Home-2

	// Start Popular Area Home-2
	.echo-popular-area {
		padding: 30px 0;
	}

	.echo-popular-area .echo-popular-content .echo-popular-full-content .echo-popular-tabing .echo-popular-tabing-item-second-row .echo-popular-left-bt-contetn .echo-popular-left-bt-img .echo-popular-left-home-2-img-main>a>img {
		width: 100%;
	}

	.echo-popular-left-main-item .echo-popular-left-img>a>img {
		width: 100%;
	}

	.echo-popular-area .echo-popular-content .echo-popular-full-content .echo-popular-tabing .echo-popular-tabing-item-frist-row .echo-popular-left-jt-heading h3 {
		font-size: 40px;
		line-height: 50px;
		margin: 0;
	}

	.echo-popular-area .echo-popular-content .echo-popular-full-content .echo-popular-tabing .echo-popular-tabing-item-second-row .echo-popular-left-bt-title h4 {
		font-size: 22px;
		line-height: 30px;
	}

	.echo-popular-area .echo-popular-content .echo-popular-full-content .echo-popular-tabing .echo-popular-tabing-item-second-row .echo-popular-left-bt-title p {
		margin: 10px 0;
	}

	.echo-popular-area .echo-popular-content .echo-popular-full-content .echo-popular-tabing .echo-popular-tabing-btn .echo-popular-tab-button {
		max-width: max-content;
	}

	.echo-popular-area .echo-popular-content .echo-popular-full-content .echo-popular-tabing .echo-feature-area-right-site-follower ul li {
		text-align: center;
	}

	.echo-feature-area-home-2-responsive {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		gap: 35px;
		justify-content: center;
	}

	.echo-popular-area .echo-popular-content .echo-popular-full-content .echo-popular-tabing .echo-feature-area-right-site-full-content .echo-feature-area-right-site-audio-news {
		border-bottom: none;
		width: 45%;
		margin: auto;
	}

	.echo-popular-area .echo-popular-content .echo-popular-full-content .echo-popular-tabing .echo-feature-area-right-site-full-content .echo-feature-area-right-site-audio-news .echo-feature-area-right-img,
	.echo-popular-area .echo-popular-content .echo-popular-full-content .echo-popular-tabing .echo-feature-area-right-site-full-content .echo-feature-area-right-site-audio-news .echo-feature-area-right-audio-text {
		flex-basis: auto;
	}

	.echo-popular-area .echo-popular-content .echo-popular-full-content .echo-popular-tabing .echo-feature-area-right-site-full-content .echo-feature-area-right-site-audio-news:last-child {
		padding-bottom: 25px;
	}

	.echo-popular-area .echo-popular-content .echo-popular-full-content .echo-popular-tabing .echo-feature-area-right-site-full-content .echo-feature-area-right-site-audio-news:first-child {
		padding: 25px 0;
	}

	// End Popular Area Home-2

	// Start Feature Area Home-2
	.echo-home-2-feature-area {
		padding: 30px 0;
	}

	.echo-home-2-feature-area .echo-home-2-feature-area-content {
		padding: 35px 0;
	}

	.echo-home-2-feature-area .echo-home-2-title .echo-home-2-main-title h4::before,
	.echo-home-2-feature-area .echo-home-2-title .echo-home-2-main-title h4::after {
		display: none;
	}

	.echo-home-2-feature-area .echo-home-2-title .echo-home-2-main-title h4 {
		font-size: 30px;
	}

	.echo-home-2-feature-area .echo-feature-content .echo-feature-main-content .echo-feature-area-post-title h4 {
		font-size: 28px;
		line-height: 38px;
	}

	.echo-home-2-featurea-area-last-child-responsive {
		margin-top: 30px;
	}

	.echo-home-2-feature-area .echo-feature-content .echo-feature-main-content .echo-feature-area-post-title p {
		font-size: 15px;
		line-height: 25px;
	}

	// End Feature Area Home-2

	// Start Banner Area Home-2
	.echo-banner {
		padding: 30px 0;
	}

	.echo-banner .echo-banner-mani-content .echo-banner-img>img {
		width: 100%;
	}

	.echo-home-2-banner-right-flexing {
		display: flex;
		align-items: center;
		margin-top: 30px;
		gap: 20px;
	}

	.echo-banner .echo-banner-right-itme {
		flex-basis: 50%;
	}

	.echo-banner .echo-banner-right-itme:last-child {
		margin-top: 0px;
	}

	.echo-banner .echo-banner-right-itme .echo-banner-right-heading h5 {
		font-size: 20px;
		line-height: 30px;
	}

	// End Banner Area Home-2

	// Start Footer Banner Area Home-1
	.echo-software-download-area {
		padding: 30px 10px;
	}

	// End Footer Banner Area Home-1

	// Start Popular News Area Home-1
	.echo-popular-news-area {
		padding: 30px 0;
	}

	.echo-popular-news-area .echo-popular-area-item-title h5 {
		font-size: 20px;
		line-height: 30px;
	}

	.echo-popular-news-area .echo-popular-area-item-title {
		margin: 10px 0;
	}

	.echo-popular-news-area .echo-popular-area-title h4::before,
	.echo-popular-news-area .echo-popular-area-title h4::after {
		display: none;
	}

	.echo-popular-area-single-item {
		margin-bottom: 20px;

		&.echo-popular-news-responsive-home-1 {
			margin-bottom: 0;
		}
	}

	// End Popular News Area Home-1

	// Start Feature Area Home-1
	.echo-feature-area {
		padding: 30px 0;
	}

	.echo-feature-area .echo-feature-area-site-title .echo-feature-area-post-hins h5 {
		margin-top: 10px;
		font-size: 22px;
		margin-bottom: 10px;
	}

	.echo-feature-area .echo-feature-area-site-title h4::after {
		display: none;
	}

	.echo-feature-area .echo-feature-area-last-news .echo-feature-area-last-content .echo-feature-area-last-content-img>a>img {
		width: 100%;
	}

	.echo-feature-area .echo-feature-area-right-site-follower ul li {
		text-align: center;
	}

	.echo-feature-area .echo-feature-area-right-site-full-content .echo-feature-area-right-site-audio-news .echo-feature-area-right-img a img {
		width: auto;
	}

	.echo-feature-area .echo-feature-area-right-site-full-content .echo-feature-area-right-site-audio-news .echo-feature-area-right-audio-text {
		flex-basis: 88%;
	}

	.echo-feature-area .echo-feature-area-right-site-full-content .echo-feature-area-right-site-audio-news .echo-feature-area-right-img {
		flex-basis: 12%;
	}

	.echo-feature-area .echo-feature-area-right-site-follower>h5 {
		font-size: 25px;
	}

	.echo-feature-area .echo-feature-area-right-site-full-content .echo-feature-area-right-site-audio-title>h5 {
		font-size: 25px;
	}

	.echo-feature-area .echo-feature-area-right-site-newsletter .echo-feature-area-rigth-site-newsletter-title>h5 {
		font-size: 25px;
	}

	.echo-feature-area .echo-feature-area-right-site-full-content .echo-feature-area-right-site-audio-news {
		display: block;
		text-align: center;
	}

	.echo-feature-area-right-site-flexing {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		gap: 40px;
	}

	.echo-feature-area .echo-feature-area-right-site-full-content hr {
		margin-top: 80px;
	}

	.echo-feature-area .echo-feature-area-right-site-full-content .echo-feature-area-right-site-audio-news .echo-feature-area-right-audio-text .echo-feature-area-right-site-audio-main-contetn .audioplayer-time-current {
		margin-left: 20px;
	}

	.echo-feature-area .echo-feature-area-right-site-full-content {
		padding-top: 40px;
		margin-top: 0;
		padding-bottom: 80px;
		width: 47%;
	}

	.echo-feature-area .echo-feature-area-right-site-newsletter {
		padding-top: 40px;
		margin-top: 0;
		width: 47%;
	}

	// End Feature Area Home-1

	//Start Home 1 Hero Banner
	.echo-home-1-hero-area-top-story {
		margin-top: 20px;
	}

	// End Home 1 Hero Banner 

	// Start De-Category Area Home-1
	.echo-de-category-area {
		padding: 30px 0;
	}

	.echo-de-category-area .echo-de-category-title-btn h4::after {
		display: none;
	}

	.echo-de-category-area .echo-de-category-content:last-child {
		margin-top: 30px;
	}

	.echo-de-category-area .echo-de-category-title-btn {
		padding-bottom: 0;
	}

	// End De-Category Area Home-1

	// Start Trending Area Home-1
	.echo-trending-area h6::after {
		display: none;
	}

	.echo-trending-area {
		padding: 30px 0;
	}

	.echo-trending-right-site-post-img img {
		width: 100%;
	}

	// End Trendingd Area Home-1

	// Start Video Area Home-1
	.echo-video-area {
		padding: 30px 0;
	}

	.echo-video-area .echo-full-video-content .echo-video-left-site>a>img {
		width: 100%;
	}

	.echo-video-area .echo-full-video-content .echo-video-right-site-content .echo-video-right-site-content-text h5 {
		font-size: 17px;
		line-height: 27px;
		margin-bottom: 8px;
	}

	.echo-video-area .echo-full-video-content .echo-video-right-site-content .echo-video-right-site-content-text hr {
		display: none;
	}

	.echo-video-area .echo-full-video-content .echo-video-area-home-1-right-content-responsive {
		display: flex;
		margin-top: 30px;
		flex-wrap: wrap;
		gap: 20px;
	}

	.echo-video-area .echo-full-video-content .echo-video-right-site-content {
		width: 45%;
	}

	.echo-video-area .echo-full-video-content .echo-video-right-site-content .echo-video-right-site-content-video {
		flex-basis: auto;
	}

	.echo-video-area .echo-full-video-content .echo-video-right-site-content .echo-video-right-site-content-text {
		flex-basis: auto;
	}

	.echo-video-area .echo-full-video-content .echo-video-right-site-content {
		gap: 5px;
	}

	.echo-video-area .echo-full-video-content .echo-video-right-site-content .echo-video-right-site-content-video .video-play-button {
		transform: translate(-50%, -50%) scale(0.5);
	}

	// End Video Area Home-1
	.echo-footer-area4 {
		padding: 0 10px;
	}
}


/* small mobile :320px. */
@media (max-width: 767px) {

	//Start Inner Section
	.echo-hero-section.inner {
		padding: 65px 20px;
	}

	.echo-hero-section.inner .echo-right-ct-1 .echo-home-1-hero-area-top-story .echo-top-story .echo-story-picture a img {
		width: unset;
		height: unset;
	}

	.echo-hero-section.inner .echo-right-ct-1 .echo-home-1-hero-area-top-story .echo-top-story .echo-story-picture {
		color: var(--colorPrimary);
	}

	.echo-hero-section.inner .echo-right-ct-1 .echo-home-1-hero-area-top-story .echo-top-story .echo-story-picture {
		flex-basis: auto;
	}

	.echo-breadcrumb-area .breadcrumb-inner .title {
		line-height: 50px;
	}
    .echo-de-category-show-more-btn {
		margin-top: 35px;
	}

	// End Inner Section

	// Start Post Style 2
	.post-style-3-lad-img {
		width: 100%;
	}

	.post-style-3-lad-img:last-child {
		margin-top: 20px;
	}

	.echo-hero-section.inner-post-3 .echo-ps-2-subscribe-box .echo-ps-2-subscribe-all-content .echo-ps-2-subscribe-title h5 {
		font-size: 18px;
		line-height: 28px;
	}

	.echo-hero-section.inner-post-3 .echo-ps-2-subscribe-box .echo-ps-2-subscribe-all-content .echo-subscribe-input-fill form input {
		width: 100%;
	}

	.echo-hero-section.inner-post-3 .echo-ps-2-subscribe-box .echo-ps-2-subscribe-all-content .echo-subscribe-input-fill form .echo-ps-2-subscribe-btn {
		right: 0;
	}

	.echo-post-style-3-2end {
		width: 100%;
	}

	.echo-post-style-3-2end:last-child {
		margin-top: 15px;
	}

	// End Post Style 2

	// Start Post Style 1
	.post-style-1-new-img {
		width: 100%;
	}

	.post-style-1-frist-hero-img {
		width: 100%;
	}

	.post-style-1-new-img:last-child {
		margin-top: 20px;
	}

	.echo-hero-section.inner-post .echo-financial-area .content .content-wrapper .wrapper-item {
		width: 100%;
	}

	.echo-hero-section.inner-post .echo-author-area {
		padding: 30px 20px 30px 0;
		gap: 25px;
	}

	.echo-hero-section.inner-post .echo-financial-area .content .details-share {
		justify-content: start;
		margin-top: 15px;
	}

	.echo-hero-section.inner-post .echo-author-area {
		display: block;
		text-align: center;
	}

	.echo-hero-section.inner-post .echo-author-area .content {
		margin-top: 15px;
	}

	.echo-hero-section.inner-post .echo-reply-area .comment-inner .wrapper {
		display: block;
	}

	.echo-hero-section.inner-post .echo-reply-area .comment-inner .wrapper .content {
		margin-top: 15px;
	}

	// End Post Style 1

	// Start About Area
	.echo-about-area .container .echo-about-area-inner .hero-image .image-2 {
		position: unset;
	}

	.echo-about-area .container .echo-about-area-inner .hero-image {
		gap: 30px;
		margin-top: 15px;
	}

	.echo-about-area .container .echo-about-area-inner .content-area .title {
		margin-bottom: 15px;
	}

	.echo-about-area .container .echo-about-area-inner .content-area .sub-title {
		font-size: 18px;
		line-height: 28px;
	}

	.echo-about-area .container .echo-about-area-inner .content-area .desc {
		margin-bottom: 15px;
	}

	.echo-about-area {
		padding: 50px 10px;
	}

	.echo-about-area .container .echo-about-area-inner .content-area .title {
		font-size: 40px;
		line-height: 50px;
	}

	.echo-about-area .container .echo-about-area-inner .hero-image .image {
		width: 50%;
	}

	// End About Area
	// Start Ct-3
	.echo-hero-section.inner-3 .echo-ct-style-bg-color .echo-griding-ct-style-3 {
		display: block;
	}

	.echo-ct-style-3-shep {
		bottom: -32px;
	}

	.echo-hero-section.inner-3 .echo-ct-style-bg-color .echo-popular-item-category .echo-popular-cat-content {
		gap: 20px;
	}

	.echo-hero-section.inner-3 .echo-ct-style-bg-color .echo-popular-item-category .echo-popular-cat-content .echo-popular-cat-img {
		flex-basis: 15%;
	}

	.echo-hero-section.inner-3 .echo-ct-style-bg-color .echo-popular-item-category .echo-popular-cat-content .echo-popular-cat-title {
		flex-basis: 85%;
	}

	.echo-hero-section.inner .echo-hero-baner {
		margin-bottom: 15px;
	}

	.echo-hero-section.inner-3 .echo-ct-style-bg-color .echo-popular-item-category {
		padding-top: 15px;
	}

	.echo-hero-section.inner-3 .echo-ct-style-bg-color .echo-popular-item-category .echo-popular-cat-content .echo-popular-cat-title h5 {
		font-size: 18px;
		line-height: 28px;
	}

	.echo-hm2-video-area .echo-hm2-video-area-content .echo-hm2-video-area-full-content .echo-row .echo-hm2-video-img .echo-hm2-video-text-box .echo-hm2-video-heading h1 {
		font-size: 22px;
		line-height: 32px;
	}

	.echo-hm2-video-area .echo-hm2-video-area-content .echo-hm2-video-area-full-content .echo-row .echo-hm2-video-img .echo-hm2-video-text-box .echo-3-align-icons {
		display: none;
	}

	// End Ct-3

	// Start Ct-2
	.echo-hero-section.inner-2 .echo-hero-baner .echo-inner-img-ct-1 a>img {
		width: 100%;
	}

	.echo-hero-section.inner-2 .echo-hero-baner {
		display: block;
		padding-bottom: 25px;
	}

	// End Ct-2

	// Start Contact
	.echo-contact-area .echo-contact-content .echo-contact-full-content .echo-contact-input-field .echo-contact-title h2 {
		margin: 0;
		font-size: 40px;
		line-height: 50px;
	}

	.echo-contact-area .echo-contact-content .echo-contact-full-content .echo-contact-input-field .echo-main-contact-form form .echo-contact-input-flexing .echo-df-input {
		flex-basis: 50%;
	}

	// End Contact
	// Start Home-2
	.echo-hm2-video-area .echo-hm2-video-area-content .echo-hm2-video-area-full-content .echo-row .echo-hm2-video-img .echo-hm2-video-text-box {
		width: 100%;
		position: unset;
		transform: none;
		height: auto;
	}

	.echo-hm2-video-area .echo-hm2-video-area-content .echo-hm2-video-area-full-content .echo-row .echo-hm2-video-img {
		height: auto;
	}

	.echo-hm2-video-area .echo-hm2-video-area-content .echo-hm2-video-area-full-content .echo-row .echo-banner-slider-button {
		display: none;
	}

	.echo-hm2-video-area .echo-hm2-video-area-content .echo-hm2-video-area-full-content .echo-row .echo-hm2-video-img .echo-hm2-video-text-box .echo-hm2-video-heading h1 {
		font-size: 22px;
		line-height: 32px;
	}

	.echo-hm2-video-area .echo-hm2-video-area-content .echo-hm2-video-area-full-content .echo-row .echo-hm2-video-img .echo-hm2-video-text-box .echo-3-align-icons a {
		flex-basis: 33.3%;
	}

	.echo-hm2-video-area .echo-hm2-video-area-content .echo-hm2-video-area-full-content .echo-row .echo-hm2-video-img .echo-hm2-video-icons {
		top: 115px;
	}

	.echo-hm2-video-area .echo-hm2-video-area-content .echo-hm2-video-area-full-content .echo-row .echo-hm2-video-img .echo-hm2-video-text-box .echo-hm2-video-discription {
		width: auto;
		height: auto;
		margin: auto;
	}

	.echo-hm2-video-area .echo-hm2-video-area-content .echo-hm2-video-area-full-content .echo-row .echo-hm2-video-img .echo-hm2-video-text-box {
		height: auto;
		padding: 0 20px;
	}
    .echo-archive-area .echo-archive-area-content .echo-archive-area-full-content .echo-home-2-title .echo-home-2-main-title h4::after{
        display: none;
    }

	// End Home-2

	// Start Category Style 1
	.echo-right-ct-1 {
		margin-top: 40px;
	}

	.echo-hero-section.inner .echo-hero-baner h2 {
		margin-top: 20px;
		line-height: 50px;
		font-size: 40px;
		margin-bottom: 10px;
	}

	.echo-hero-section.inner .echo-hero-baner .echo-hero-area-titlepost-post-like-comment-share {
		gap: 10px;
	}

	// Start Social Media Home-2
	.echo-social-media-area-hm2 {
		padding: 30px 0 0 0;
	}

	.echo-social-media-area-hm2 .echo-home-2-title .echo-home-2-main-title h4::before {
		display: none;
	}

	.echo-social-media-area-hm2 .echo-home-2-title .echo-home-2-main-title h4::after {
		display: none;
	}

	.echo-social-media-area-hm2 .echo-social-media-area-content .echo-social-media-main-content {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
	}

	.echo-social-media-area-hm2 .echo-social-media-area-content .echo-social-media-main-content .echo-hm2-social-item {
		width: 100%;

	}

	// End Social Media Home-2

	// Start Archive Area Home-2
	.echo-archive-area {
		padding: 0px 0;
	}

	.echo-archive-area .echo-archive-area-content .echo-archive-area-full-content .echo-archive-area-slider-content .echo-banner-mani-content .echo-banner-img>img {
		width: 100%;
	}

	.echo-archive-main-content.area-2 {
		margin-bottom: 30px;
	}

	.echo-archive-area .echo-archive-area-content .echo-archive-area-full-content .echo-archive-area-right-site .echo-archive-right-content {
		flex-direction: row-reverse;
		justify-content: left;
        align-items: center;
	}

	.echo-archive-area .echo-archive-area-content .echo-archive-area-full-content .echo-archive-area-right-site {
		max-width: max-content;
	}

	.echo-archive-area .echo-archive-area-content .echo-archive-area-full-content .echo-archive-area-right-site .echo-archive-right-content .echo-archive-right-text {
		flex-basis: auto;
	}

	.echo-archive-area .echo-archive-area-content .echo-archive-area-full-content .echo-archive-area-right-site .echo-archive-right-content .echo-archive-right-img {
		flex-basis: auto;
	}

	.echo-archive-area .echo-archive-area-content .echo-archive-area-full-content .echo-archive-area-slider-content .echo-archive-main-content.area-2 .echo-banner-mani-content .echo-banner-text .echo-banner-heading h1 {
		font-size: 30px;
		line-height: 40px;
	}

	.echo-archive-area .echo-archive-area-content .echo-archive-area-full-content .echo-archive-area-slider-content .echo-banner-mani-content .echo-banner-text {
		padding: 15px;
	}

	.echo-archive-area .echo-archive-area-content .echo-archive-area-full-content .echo-archive-area-right-site .echo-archive-right-content .echo-archive-right-text .echo-3-align-icons {
		margin: 10px 0;
	}

	.echo-archive-area .echo-archive-area-content .echo-archive-area-full-content .echo-archive-area-right-site .echo-archive-right-content .echo-archive-right-text .echo-archive-right-heading h5 {
		font-size: 20px;
		line-height: 30px;
	}

	// End Archive Area Home-2

	// Start Fd Post Area Home-2
	.echo-fd-post-area {
		padding: 30px 0;
	}

	.echo-fd-post-area .echo-fd-post-content {
		padding: 40px 0;
	}

	.echo-fd-post-area .echo-fd-post-content .echo-fd-post-full-content .echo-home-2-title .echo-home-2-main-title h4::before,
	.echo-fd-post-area .echo-fd-post-content .echo-fd-post-full-content .echo-home-2-title .echo-home-2-main-title h4::after {
		display: none;
	}

	.echo-fd-post-left {
		margin-bottom: 30px;
	}

	.echo-fd-post-area .echo-fd-post-content .echo-fd-post-full-content .echo-fd-post-main-content .echo-fd-post-right-area .echo-fd-post-right-text .echo-fd-post-right-heading h4 {
		font-size: 20px;
		line-height: 30px;
	}

	.echo-fd-post-area .echo-fd-post-content .echo-fd-post-full-content .echo-fd-post-main-content .echo-3-align-icons {
		gap: 13px;
	}

	.echo-fd-post-area .echo-fd-post-content .echo-fd-post-full-content .echo-fd-post-left .echo-fd-post-left-img>a>img {
		width: 100%;
	}

	// End Fd Post Area Home-2

	// Start Cl Blocks Area Home-2
	.echo-cl-blocks-area {
		padding: 30px 0;
	}

	.echo-cl-blocks-area .echo-cl-blocks-content .echo-cl-blocks-full-content .echo-cl-blocks-img>a>img {
		width: 100%;
	}

	// End Cl Blocks Area Home-2

	// Start Popular Area Home-2
	.echo-popular-area {
		padding: 30px 0;
	}

	.echo-popular-area .echo-popular-content .echo-popular-full-content .echo-popular-tabing .echo-popular-tabing-item-second-row .echo-popular-left-bt-contetn .echo-popular-left-bt-img .echo-popular-left-home-2-img-main>a>img {
		width: 100%;
	}

	.echo-popular-left-main-item .echo-popular-left-img>a>img {
		width: 100%;
	}

	.echo-popular-area .echo-popular-content .echo-popular-full-content .echo-popular-tabing .echo-popular-item-category .echo-popular-cat-content .echo-popular-cat-title h6 {
		font-size: 18px;
		line-height: 28px;
	}

	.echo-popular-left-bt-contetn.three {
		margin: 30px 0;
	}

	.echo-popular-left-jt-text.two {
		margin-top: 30px;
	}

	.echo-popular-area .echo-popular-content .echo-popular-full-content .echo-popular-tabing .echo-popular-tabing-btn .echo-popular-tab-button {
		max-width: max-content;
		justify-content: center;
	}

	.echo-popular-area .echo-popular-content .echo-popular-full-content .echo-popular-tabing .echo-popular-tabing-btn .echo-popular-tab-button li::after {
		display: none;
	}

	.echo-popular-area .echo-popular-content .echo-popular-full-content .echo-popular-tabing .echo-popular-tabing-btn .echo-popular-tab-button li button.active::before {
		display: none;
	}

	.echo-popular-area .echo-popular-content .echo-popular-full-content .echo-popular-tabing .echo-feature-area-right-site-follower ul li {
		text-align: center;
	}

	.echo-feature-area-home-2-responsive {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		gap: 35px;
	}

	.echo-popular-area .echo-popular-content .echo-popular-full-content .echo-popular-tabing .echo-feature-area-right-site-full-content .echo-feature-area-right-site-audio-news {
		border-bottom: none;
		width: 45%;
		margin: 0 auto;
	}

	.echo-popular-area .echo-popular-content .echo-popular-full-content .echo-popular-tabing .echo-feature-area-right-site-full-content .echo-feature-area-right-site-audio-news .echo-feature-area-right-audio-text h5 {
		font-size: 14px;
		line-height: 24px;
	}

	.echo-popular-area .echo-popular-content .echo-popular-full-content .echo-popular-tabing .echo-feature-area-right-site-full-content .echo-feature-area-right-site-audio-news .echo-feature-area-right-img,
	.echo-popular-area .echo-popular-content .echo-popular-full-content .echo-popular-tabing .echo-feature-area-right-site-full-content .echo-feature-area-right-site-audio-news .echo-feature-area-right-audio-text {
		flex-basis: auto;
	}

	.echo-popular-area .echo-popular-content .echo-popular-full-content .echo-popular-tabing .echo-feature-area-right-site-full-content .echo-feature-area-right-site-audio-news:last-child {
		padding-bottom: 0px;
	}

	.echo-popular-area .echo-popular-content .echo-popular-full-content .echo-popular-tabing .echo-feature-area-right-site-full-content .echo-feature-area-right-site-audio-news:first-child {
		padding: 0 0;
	}

	.echo-popular-area .echo-popular-content .echo-popular-full-content .echo-home-2-title {
		margin-bottom: 25px;
	}

	.echo-popular-area .echo-popular-content .echo-popular-full-content .echo-popular-tabing .echo-popular-tabing-btn .echo-popular-tab-button li {
		margin: 5px 10px;
	}

	// End Popular Area Home-2

	// Start Feature Area Home-2
	.echo-home-2-feature-area {
		padding: 30px 0;
	}

	.echo-home-2-feature-area .echo-home-2-feature-area-content {
		padding: 35px 0;
	}

	.echo-home-2-feature-area .echo-home-2-title .echo-home-2-main-title h4::before,
	.echo-home-2-feature-area .echo-home-2-title .echo-home-2-main-title h4::after {
		display: none;
	}

	.echo-home-2-feature-area .echo-home-2-title .echo-home-2-main-title h4 {
		font-size: 30px;
	}

	.echo-home-2-feature-area .echo-feature-content .echo-feature-main-content .echo-feature-area-post-title h4 {
		font-size: 28px;
		line-height: 38px;
	}

	.echo-feature-main-content {
		margin-bottom: 30px;

		&.echo-home-2-featurea-area-last-child-responsive {
			margin-bottom: 0;
		}
	}

	.echo-home-2-feature-area .echo-feature-content .echo-feature-main-content .echo-feature-area-post-title p {
		font-size: 15px;
		line-height: 25px;
	}

	// End Feature Area Home-2

	// Start Banner Area Home-2
	.echo-banner {
		padding: 30px 0;
	}

	.echo-banner .echo-banner-mani-content .echo-banner-text .echo-banner-heading h1 {
		font-size: 20px;
		line-height: 30px;
	}

	.echo-home-2-banner-right-flexing {
		margin-top: 30px;
	}

	.echo-banner .echo-banner-mani-content .echo-banner-text {
		background-color: rgba(249, 249, 249, 0.7);
		padding: 20px;
		width: 100%;
	}

	.echo-banner .echo-banner-right-itme {
		text-align: center;
	}

	.echo-banner .echo-banner-mani-content .echo-banner-text .echo-3-align-icons a {
		font-size: 14px;
		color: #5E5E5E;
	}

	.echo-banner .echo-banner-right-itme .echo-banar-right-img>a>img {
		width: 75%;
	}

	.echo-banner .echo-banner-mani-content .echo-banner-text .echo-banner-heading {
		margin-top: 5px;
	}

	// End Banner Area Home-2

	// Start Footer Banner Area Home-1
	.echo-software-download-area {
		padding: 30px 5px;
	}

	.echo-software-download-area .echo-software-download-full-content-bg .echo-software-download-full-content .echo-software-download-text-content .echo-software-download-place {
		display: block;
	}

	.echo-software-download-area .echo-software-download-full-content-bg .echo-software-download-full-content .echo-software-download-text-content .echo-software-download-place>a {
		display: block;
		margin-top: 15px;
	}

	.echo-software-download-area .echo-software-download-full-content-bg .echo-software-download-full-content .echo-software-download-text-content .echo-software-download-title h2 {
		font-size: 25px;
		line-height: 35px;
	}

	.echo-banner .echo-banner-right-itme .echo-banner-right-heading h5 {
		font-size: 20px;
		line-height: 30px;
	}

	// End Footer Banner Area Home-1

	// Start Popular News Area Home-1
	.echo-popular-news-area {
		padding: 30px 0;
	}

	.echo-popular-news-area .echo-popular-area-item-title h5 {
		font-size: 20px;
		line-height: 30px;
	}

	.echo-popular-news-area .echo-popular-area-item-title {
		margin: 10px 0;
	}

	.echo-popular-news-area .echo-popular-area-title h4::before,
	.echo-popular-news-area .echo-popular-area-title h4::after {
		display: none;
	}

	.echo-popular-area-single-item {
		margin-bottom: 20px;

		&.echo-popular-news-responsive-home-1 {
			margin-bottom: 0;
		}
	}

	// End Popular News Area Home-1

	// Start Feature Area Home-1
	.echo-feature-area {
		padding: 30px 0;
	}

	.echo-feature-area .echo-feature-area-site-title h4::after {
		display: none;
	}

	.echo-feature-area-post:first-child {
		margin-bottom: 30px;
	}

	.echo-feature-area .echo-feature-area-right-site-follower>h5 {
		font-size: 25px;
	}

	.echo-feature-area .echo-feature-area-right-site-full-content .echo-feature-area-right-site-audio-title>h5 {
		font-size: 25px;
	}

	.echo-feature-area .echo-feature-area-right-site-newsletter .echo-feature-area-rigth-site-newsletter-title>h5 {
		font-size: 25px;
	}

	.echo-feature-area .echo-feature-area-right-site-full-content .echo-feature-area-right-site-audio-news .echo-feature-area-right-img {
		flex-basis: auto;
	}

	.echo-feature-area .echo-feature-area-right-site-full-content .echo-feature-area-right-site-audio-news .echo-feature-area-right-audio-text {
		flex-basis: auto;
	}

	.echo-feature-area .echo-feature-area-last-news .echo-feature-area-last-content .echo-feature-area-last-content-text h3 {
		font-size: 25px;
		line-height: 35px;
	}

	.echo-feature-area .echo-feature-area-last-news .echo-feature-area-last-content .echo-feature-area-last-content-text .echo-feature-area-last-content-read-view {
		flex-wrap: wrap;
		gap: 5px;
	}

	// End Feature Area Home-1

	// Start Home-1 Hero Area
	.echo-home-1-hero-area-top-story {
		margin-top: 20px;
	}

	// End Home -1 Hero Area
	// Start De-Category Area Home-1
	.echo-de-category-area {
		padding: 30px 0;
	}

	.echo-de-category-area .echo-de-category-title-btn h4::after {
		display: none;
	}

	.echo-de-category-area .echo-de-category-title-btn a {
		display: none;
	}

	.echo-de-category-area .echo-de-category-title-btn {
		padding-bottom: 20px;
	}

	.echo-de-category-area .echo-de-category-content .echo-de-category-content-img-title .echo-de-category-content-img {
		flex-basis: 140px;
	}

	.echo-de-category-area .echo-de-category-content {
		margin-top: 30px;

		&.echo-responsive-wd {
			margin-top: 0;
		}
	}

	.echo-de-category-area .echo-de-category-content>h5 {
		font-size: 25px;
	}

	// End De-Category Area Home-1

	// Start Trending Area Home-1
	.echo-trending-area {
		padding: 30px 0;
	}

	.echo-trending-area h6::after {
		display: none;
	}

	// End Trending Area Home-1

	// Start Video Area Home-1
	.echo-video-area {
		padding: 30px 0;
	}

	.echo-video-area .echo-full-video-content .echo-video-right-site-content .echo-video-right-site-content-video {
		flex-basis: auto !important;
	}

	.echo-video-area .echo-full-video-content .echo-video-right-site-content {
		justify-content: space-between;
	}

	.echo-video-area .echo-full-video-content .echo-video-right-site-content .echo-video-right-site-content-text {
		flex-basis: auto;
	}

	.echo-video-area .echo-full-video-content .echo-video-area-home-1-right-content-responsive {
		margin-top: 30px;
	}

	.echo-video-area .echo-full-video-content .echo-video-left-site .echo-video-left-site-text-box {
		max-width: 100%;
		min-height: auto;
		position: relative !important;
	}

	// End Video Area Home-1
	.echo-footer-area4 {
		padding: 0 10px;
	}
	.hm5-feature-area .hm5-feature-content .hm5-featurea-main-content .hm5-featurea-meta-content .feature-img-content-btn{
		margin-top: 30px;
	}
}

/* small mobile :320px. */
@media (max-width: 500px) {

	// Start 404 Area
	.echo-404-page .echo-404-area .echo-container .echo-error-content .echo-error h1 {
		font-size: 200px !important;
		line-height: 70px !important;
	}

	.echo-404-page .echo-404-area .echo-container .echo-error-content .echo-error-sub-heading h3 {
		font-size: 40px !important;
		line-height: 50px !important;
	}

	// End 404 Area
	//Start Inner Section
	.echo-hero-section.inner {
		padding: 65px 15px;
	}
	.echo-banner-innerpage{
		padding: 60px 15px 0 15px;
	}
	.echo-hero-section.inner-post-3 .echo-post-style-quote-info-flexing{
		display: block;
	}
	.echo-hero-section.inner-post-3 .echo-post-style-quote-info-flexing .echo-quote-area::after{
		display: none;
	}
	.echo-hero-section.inner .echo-right-ct-1 .echo-home-1-hero-area-top-story .echo-top-story .echo-story-picture a img {
		width: unset;
		height: unset;
	}

	.echo-hero-section.inner .echo-right-ct-1 .echo-home-1-hero-area-top-story .echo-top-story .echo-story-picture {
		color: var(--color-Primary);
	}

	.echo-hero-section.inner .echo-right-ct-1 .echo-home-1-hero-area-top-story .echo-top-story .echo-story-picture {
		flex-basis: auto;
	}

	.echo-breadcrumb-area .breadcrumb-inner .title {
		line-height: 50px;
		font-size: 36px;
	}

	// End Inner Section
	// Start Post Style - 2
	.echo-hero-section.inner-post-3 .echo-ps-2-subscribe-box .echo-ps-2-subscribe-all-content .echo-subscribe-input-fill form input {
		width: 100%;
	}

	.echo-hero-section.inner-post-3 .echo-ps-2-subscribe-box .echo-ps-2-subscribe-all-content .echo-subscribe-input-fill form .echo-ps-2-subscribe-btn {
		right: 0;
	}

	.echo-hero-section.inner-post .echo-more-news-area .echo-top-story .echo-story-text h6 {
		font-size: 18px;
		line-height: 28px;
	}

	// End Post Style - 2

	// Start Post Style-1
	.post-style-1-frist-hero-img {
		width: 100%;
	}

	.echo-hero-section.inner-post .echo-financial-area .content .content-wrapper .wrapper-item {
		font-size: 18px;
		line-height: 28px;
	}

	.echo-hero-section.inner-post .echo-financial-area .content .title {
		font-size: 26px;
		line-height: 36px;
	}

	// End Post Style-1
	// Start About Area
	.echo-about-area {
		padding: 0 10px;
	}

	.echo-about-area .container .echo-about-area-inner .hero-image .image-2 {
		position: unset;
		margin: auto;
		margin-top: 20px;
	}

	.echo-about-area .container .echo-about-area-inner .hero-image .image {
		margin: 20px auto 0 auto;
		width: auto;
	}

	.echo-about-area .container .echo-about-area-inner .hero-image {
		gap: 30px;
		margin-top: 15px;
		display: block;
	}

	.echo-about-area .container .echo-about-area-inner .content-area .title {
		margin-bottom: 15px;
	}

	.echo-about-area .container .echo-about-area-inner .content-area .sub-title {
		font-size: 16px;
		line-height: 26px;
	}

	.echo-about-area .container .echo-about-area-inner .content-area .desc {
		margin-bottom: 15px;
	}

	.echo-about-area {
		padding: 50px 10px;
	}

	.echo-about-area .container .echo-about-area-inner .content-area .title {
		font-size: 30px;
		line-height: 40px;
	}

	.echo-about-area .container .echo-about-area-inner .content-area .bottom-author-area .content::before {
		display: none;
	}

	.echo-about-area .container .echo-about-area-inner .content-area .bottom-author-area .content .author-name {
		font-size: 16px;
		line-height: 26px;
	}

	// End About Area
	// Start Home 2
	.echo-hm2-video-area .echo-hm2-video-area-content .echo-hm2-video-area-full-content .echo-row .echo-hm2-video-img .echo-hm2-video-icons {
		top: 65px;
	}

	// End Home 2

	// Start Ct-3
	.echo-hero-section.inner-3 .echo-ct-style-bg-color .echo-popular-item-category .echo-popular-cat-content .echo-popular-cat-img {
		flex-basis: 25%;
	}

	.echo-hero-section.inner-3 .echo-ct-style-bg-color .echo-popular-item-category .echo-popular-cat-content .echo-popular-cat-title {
		flex-basis: 65%;
	}

	// End Ct-3


	// Start Ct-2
	.echo-hero-section.inner-2 .echo-hero-baner .echo-banner-texting h3 {
		font-size: 25px;
		line-height: 35px;
	}

	// End Ct-2

	// Start Category Style 1
	.echo-hero-section.inner .echo-hero-baner h2 {
		line-height: 32px;
		font-size: 22px;
	}

	// End Category Style 1

	// Start Social Media Area Home-2
	.echo-social-media-area-hm2 .echo-social-media-area-content .echo-social-media-main-content .echo-hm2-social-item {
		width: auto;
		margin: auto;
	}

	.echo-social-media-area-hm2 .echo-social-media-area-content .echo-social-media-main-content .echo-hm2-social-item>img {
		width: 100%;
	}

	// End Social Media Area Home-2

	// Start Archive Area Home-2
	.echo-archive-area .echo-archive-area-content .echo-archive-area-full-content .echo-archive-area-slider-content .echo-banner-mani-content .echo-banner-text .echo-3-align-icons {
		display: none;
	}

	.echo-archive-area .echo-archive-area-content .echo-archive-area-full-content .echo-archive-area-slider-content .echo-archive-main-content.area-2 .echo-banner-mani-content .echo-banner-text .echo-banner-heading h1 {
		font-size: 16px;
		line-height: 26px;
	}

	.echo-archive-area .echo-archive-area-content .echo-archive-area-full-content .echo-archive-area-right-site .echo-archive-right-content .echo-archive-right-text .echo-3-align-icons {
		display: none;
	}

	.echo-archive-area .echo-archive-area-content .echo-archive-area-full-content .echo-archive-area-right-site .echo-archive-right-content .echo-archive-right-text .echo-archive-right-heading h5 {
		font-size: 18px;
		line-height: 28px;
	}
    .echo-archive-right-heading {
        margin-top: 10px;
    }
	// End Archive Area Home-2

	// Start Fd Post Area Home-2
	.echo-fd-post-area .echo-fd-post-content .echo-fd-post-full-content .echo-fd-post-main-content .echo-3-align-icons {
		gap: 13px;
		flex-wrap: wrap;
	}

	.echo-fd-post-area .echo-fd-post-content .echo-fd-post-full-content .echo-fd-post-main-content .echo-fd-post-left-title h3 {
		font-size: 30px;
		line-height: 40px;
	}

	.echo-fd-post-area .echo-fd-post-content .echo-fd-post-full-content .echo-fd-post-main-content .echo-fd-post-right-area .echo-fd-post-right-content {
		width: 100%;
	}

	.echo-fd-post-area .echo-fd-post-content .echo-fd-post-full-content .echo-fd-post-main-content .echo-fd-post-right-area {
		display: block;
	}

	.echo-fd-post-area .echo-fd-post-content .echo-fd-post-full-content .echo-fd-post-main-content .echo-fd-post-right-area .echo-fd-post-right-text {
		float: none;
	}
    .echo-fd-post-area .echo-fd-post-content .echo-fd-post-full-content .echo-fd-post-main-content .echo-fd-post-right-area .echo-fd-post-right-text .echo-fd-post-right-area-shep{
        margin-top: 15px;
    }
	// End Fd Post Area Home-2

	// Start Cl Blocks Area Home-2
	.echo-cl-blocks-area .echo-cl-blocks-content .echo-cl-blocks-full-content .echo-cl-blocks-text .echo-cl-blocks-title h3 {
		font-size: 25px;
		line-height: 35px;
	}

	// End Cl Blocks Area Home-2

	// Start Popular Area Home-2
	.echo-popular-area .echo-popular-content .echo-popular-full-content .echo-popular-tabing .echo-feature-area-right-site-follower ul li {
		font-size: 13px;
	}

	.echo-popular-area .echo-popular-content .echo-popular-full-content .echo-home-2-title h4 {
		font-size: 25px;
		line-height: 35px;
	}

	.echo-popular-area .echo-popular-content .echo-popular-full-content .echo-popular-tabing .echo-feature-area-right-site-full-content .echo-feature-area-right-site-audio-news {
		width: 100%;
	}

	.echo-feature-area-home-2-responsive {
		display: block;
	}

	.echo-popular-area .echo-popular-content .echo-popular-full-content .echo-popular-tabing .echo-feature-area-right-site-full-content .echo-feature-area-right-site-audio-news:first-child {
		padding: 25px 0;
	}

	.echo-popular-area .echo-popular-content .echo-popular-full-content .echo-popular-tabing .echo-feature-area-right-site-full-content .echo-feature-area-right-site-audio-news {
		border-bottom: 1px solid rgba(95, 95, 95, 0.5);
	}

	// End Popular Area Home-2

	// Start Banner Area Home-2
	.echo-banner .echo-banner-mani-content .echo-banner-text .echo-3-align-icons {
		display: none;
	}

	.echo-banner .echo-banner-mani-content .echo-banner-text {
		background-color: rgba(249, 249, 249);
	}

	// Start Feature Area Home-1
	.echo-feature-area {
		padding: 30px 15px;
	}

	.echo-audio-news-home-1-flexing>hr {
		display: none;
	}

	.echo-feature-area .echo-feature-area-last-news .echo-feature-area-last-content {
		display: block;

		&:last-child {
			padding-bottom: 15px;
		}
	}

	.echo-feature-area .echo-feature-area-last-news .echo-feature-area-last-content .echo-feature-area-last-content-text h3 {
		font-size: 22px;
		line-height: 32px;
	}

	.echo-feature-area .echo-feature-area-option .echo-feature-area-option-content .echo-feature-area-option-content-text {
		flex-basis: 65%;
	}

	.echo-feature-area .echo-feature-area-right-site-follower ul li {
		font-size: 13px;
	}

	.echo-feature-area .echo-feature-area-right-site-newsletter .echo-feature-area-right-site-news-subscribe form .echo-feature-area-right-site-input-box input[type=email] {
		width: 200px;
	}

	.echo-feature-area .echo-feature-area-site-title .echo-feature-area-post-hins h5 {
		margin-top: 15px;
		margin-bottom: 8px;
	}

	.echo-feature-area .echo-feature-area-last-news .echo-feature-area-last-content .echo-feature-area-last-content-text {
		margin-top: 15px;
	}

	.echo-feature-area .echo-feature-area-last-news .echo-feature-area-last-content .echo-feature-area-last-content-text h3 {
		margin-bottom: 8px;
	}

	.echo-feature-area .echo-feature-area-last-news .echo-feature-area-last-content .echo-feature-area-last-content-img>a>img {
		width: 100%;
	}

	.echo-feature-area .echo-feature-area-right-site-full-content .echo-feature-area-right-site-audio-news {
		display: flex;
		gap: 10px;
		padding: 30px 0;
	}

	.echo-feature-area .echo-feature-area-right-site-full-content .echo-feature-area-right-site-audio-news .echo-feature-area-right-audio-text .echo-feature-area-right-site-audio-main-contetn .audioplayer-time-current {
		margin-left: 17px;
		font-size: 12px;
	}

	.echo-feature-area .echo-feature-area-right-site-full-content .echo-feature-area-right-site-audio-news .echo-feature-area-right-audio-text h5 {
		font-size: 14px;
		margin-bottom: 0;
		line-height: 16px;
	}

	// End Feature Area Home-1

	// Start De-Category Area Home-1
	.echo-de-category-area {
		padding: 30px 15px;
	}

	.echo-de-category-area .echo-de-category-title-btn h4::after {
		display: none;
	}

	.echo-de-category-area .echo-de-category-content .echo-de-category-content-img-title .echo-de-category-content-title h6 {
		font-size: 16px;
		line-height: 22px;
	}

	.echo-de-category-area .echo-de-category-content .echo-de-category-content-img-title .echo-de-category-read a {
		font-size: 13px;
	}

	.echo-de-category-area .echo-de-category-content {
		padding: 20px;
	}

	.echo-de-category-area .echo-de-category-content .echo-de-category-content-img-title .echo-de-category-content-img {
		flex-basis: 120px;
	}

	.echo-de-category-area .echo-de-category-content .echo-de-category-content-img-title .echo-de-category-content-title {
		flex-basis: 60%;
	}

	// End De-Category Area Home-1

	// Start Trending Area Home-1
	.echo-trending-area .echo-trending-full-content .echo-trending-right-site-post-title h5 {
		margin-bottom: 8px;
		margin-top: 15px;
	}

	.echo-trending-area {
		padding: 30px 15px;
	}

	.echo-trending-area h6::after {
		display: none;
	}

	.echo-trending-area .echo-trending-full-content .echo-trending-right-site-post .echo-trending-right-site-post-title h4 {
		margin: 15px 0 8px 0;
	}

	.echo-trending-area .echo-trending-full-content .echo-trending-right-site-post .echo-trending-right-site-like-comment-share-icons {
		gap: 10px;
	}

	// End Trending Area Home-1

	// Start Video Area Home-1
	.echo-video-area {
		padding: 30px 15px;
	}

	.echo-video-area .echo-full-video-content .echo-video-right-site-content {
		display: grid;
		margin-top: 15px;
		justify-content: inherit;

		&:first-child {
			margin-top: 0;
		}
	}

	.echo-video-area .echo-full-video-content .echo-video-right-site-content .echo-video-right-site-content-video>a>img {
		width: 100%;
	}

	.echo-video-area .echo-full-video-content .echo-video-right-site-content .echo-video-right-site-content-text {
		order: 2;
	}

	.echo-video-area .echo-full-video-content .echo-video-left-site .video-play-button {
		top: 20%;
	}

	.echo-video-area .echo-full-video-content .echo-video-right-site-content {
		gap: 15px;
	}

	.echo-video-area .echo-full-video-content .echo-video-right-site-content .echo-video-right-site-content-text hr {
		display: none;
	}

	.echo-video-area .echo-full-video-content .echo-video-left-site .echo-video-left-site-text-box {
		max-width: 100%;
		min-height: auto;
		position: relative !important;
	}

	.echo-video-area .echo-full-video-content .echo-video-left-site .echo-video-left-site-text-box .echo-video-left-site-read-views {
		flex-wrap: wrap;
	}

	.echo-video-area .echo-video-content .echo-video-area-title-row {
		h6 {
			text-align: left;

			&::after,
			&::before {
				display: none;
			}
		}
	}

	.echo-video-area .echo-full-video-content .echo-video-left-site .echo-video-left-site-text-box h5 {
		font-size: 25px;
		line-height: 32px;
	}

	// End Video Areaq Home-1
	.echo-footer-area4 {
		padding: 0 10px;
	}

	.echo-footer-area4 .footer-main .footer-main-inner .social-area .social-icon a {
		width: 50px;
		height: 50px;
		line-height: 50px;
		font-size: 20px;
		border-radius: 15px;
	}

	.echo-popular-news-area.home-four .echo-popular-news-area-content .echo-popular-news-area-full-content .left-side-content .echo-latest-news-main-content {
		margin: 0 0 30px 0;
	}

	.echo-feature-area.home-four .echo-feature-area-full-content .right-side-inner .echo-feature-ad-area img {
		width: 100%;
	}

	.echo-popular-news-area.home-four .echo-popular-news-area-content .echo-popular-news-area-full-content .left-side-content .echo-latest-news-main-content .echo-latest-news-img img {
		width: 100%;
	}

	// Start Hero Area Home-1
	.echo-hero-section .echo-hero-baner .echo-hero-area-titlepost-post-like-comment-share {
		gap: 8px;
	}

	.echo-hero-section .echo-hero-baner {
		padding: 0;
	}

	.echo-hero-section .echo-hero-baner h1>a>br {
		display: none;
	}

	// End Hero Area Home-1

	// Start Latest News Area Home-1
	.echo-latest-news-area .echo-latest-news-main-content .echo-latest-news-single-title h5 {
		margin: 15px 0 8px 0;
	}

	.echo-latest-news-area {
		padding: 30px 15px;
	}

	// End Latest News Area Home-1

	// Start Popular News Area Home-1
	.echo-feature-area .echo-feature-area-last-news .echo-feature-area-last-content .echo-feature-area-last-content-text h3 {
		margin: 15px 0 8px 0;
	}

	.echo-popular-news-area {
		padding: 30px 15px;
	}

	// End Popular News Area Home-1

	// Start Our Software Download Area Home-1
	.echo-software-download-area {
		padding: 30px 15px;
	}

	// End Our Software Download Area Hoem-1

}
@media (max-width:1172px){
	.echo-site-main-logo-menu-social ul.echo-desktop-menu li.echo-has-dropdown:hover ul.mega-menu{
		width: 990px;
	}
	.echo-site-main-logo-menu-social ul.echo-desktop-menu li.echo-has-dropdown ul.mega-menu h5{
		font-size: 18px;
	}
}